import CryptoJS from "crypto-js";
let Base64 = require("js-base64").Base64;
// console.log(Base64.encode('abc'), CryptoJS.enc.Base64.stringify('abc'))
/**
 *
 * 第一个参数word是待加密或者解密的字符串；
 * 第二个参数keyStr是aes加密需要用到的16位字符串的key；
 * 第三个参数是初始化向量 iv。
 */
export function encrypt(word, keyStr, ivStr) {
  const key = CryptoJS.enc.Latin1.parse(keyStr);
  const iv = CryptoJS.enc.Latin1.parse(ivStr);
  const encoded = CryptoJS.AES.encrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    adding: CryptoJS.pad.ZeroPadding,
  }).toString();
  return encoded;
}

export function encodeBase64(data) {
  return Base64.encode(data);
}
export function decodeBase64(data) {
  return Base64.decode(data);
}
export function HmacSHA256(data, hash) {
  return CryptoJS.HmacSHA256(data, hash).toString();
}

export function randomString(len) {
  len = len || 16;
  var $chars =
    "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
